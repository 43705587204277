import { Outlet } from "@remix-run/react";

// Auth layout
export default function AuthLayout() {
  return (
    <main className="absolute bottom-0 left-0 right-0 top-0 flex flex-col overflow-auto bg-radial-gradient from-white to-gray-100 dark:from-gray-800 dark:to-gray-1000">
      <div className="flex flex-1 flex-col justify-center px-6 py-20">
        <Outlet />
      </div>
    </main>
  );
}
